import React from 'react'
import styles from '../Checkout.module.scss'
import Button from '@material-ui/core/Button'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import EditAddress from './editAddress'
import { useSelector } from 'react-redux'
import { ClassNamesFn } from 'classnames/types'

type checkoutAdressType = {
   cx: ClassNamesFn
   activeStep: number
   addresses: object[]
   handleSelectedAddress: (id: number) => void
   selectedAddress: number
   editAddress: any
   editAddressDrawerOpen: any
   toggleEditAddressDrawer: (index: number | null) => void
   handleEditAddress: (label: string, value: any, id: number) => void
   saveEditAdress: (idx: number) => void
   handleCepConsult: (cep: any, type: string) => void
   toggleResumeCart: (bool: boolean) => void
   
   errorsAddress: any
}

const checkoutAddress: React.FC<checkoutAdressType> = ({
   cx,
   activeStep,
   addresses,
   handleSelectedAddress,
   selectedAddress,
   editAddressDrawerOpen,
   toggleEditAddressDrawer,
   editAddress,
   handleEditAddress,
   saveEditAdress,
   handleCepConsult,
   toggleResumeCart,
   
   errorsAddress
}) => {
   const className = cx({
      displayBlock: activeStep === 0,
      mainDiv: activeStep === 0,
      displayNone: activeStep !== 0
   });

   const classNameVerPedido = cx({
      displayBlock: activeStep === 0,
      divVerPedido: activeStep === 0,
      displayNone: activeStep !== 0
   });

   return (
      <>
         <div onClick={() => toggleResumeCart(true)} className={classNameVerPedido}>
            <p className={styles.subtitles}>VER PEDIDO</p>
         </div>
         <div className={className}>
            <div className={styles.divSubtitle}>
               <p className={styles.subtitles}>Endereço de entrega</p>
            </div>
            {addresses.map((address: any, index: number) => {
               const selectedAddressClass = cx({
                  address: true,
                  selectedAddress: index === selectedAddress
               })

               const selectedAddressButtons = cx({
                  buttonsAddress: true,
                  buttonsSelectedAddress: index === selectedAddress
               })

               return (
                  <div key={index}>
                     <div className={selectedAddressClass}>
                        <div style={{ maxWidth: '85%' }}>
                           {address.firstname} {address.lastname} <br />
                           {address.street[0]}, {address.street[1]}, {address.street[3] && <>{address.street[3]},</>} {address.street[2]} <br />
                           {address.city}, {address.region.region_code} <br />
                        CEP: {address.postcode} <br />
                           {address.telephone.replace(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/, "($1) $2-$3")}
                        </div>

                        <div className={selectedAddressButtons}>
                           <Button
                              disableTouchRipple
                              variant='text'
                              color='primary'
                              size='small'
                              style={{ padding: 0 }}
                              onClick={() => toggleEditAddressDrawer(index)}
                              classes={{ label: styles.labelButton }}
                           >
                              Editar
                           </Button>
                           
                           {index === selectedAddress ?
                              <CheckCircleOutlineRoundedIcon
                                 color='primary'
                                 className={styles.checkIcon}
                              /> :
                              <Button
                                 disableTouchRipple
                                 onClick={() => { handleSelectedAddress(index) }}
                                 variant='text'
                                 color='primary'
                                 size='small'
                                 style={{ padding: 0 }}
                                 classes={{ label: styles.labelButton }}
                              >
                                 Entregar aqui
                           </Button>
                           }
                        </div>
                     </div>

                     {
                        editAddressDrawerOpen === index ?
                           <EditAddress
                              handleCepConsult={handleCepConsult}
                              toggleEditAddressDrawer={toggleEditAddressDrawer}
                              handleEditAddress={handleEditAddress}
                              idx={index}
                              editAddress={editAddress}
                              saveEditAdress={saveEditAdress}
                              errorsAddress={errorsAddress}
                           /> : null
                     }

                  </div>
               )
            })}
         </div>
      </>
   )
}

export default checkoutAddress
