import React from 'react'
import styles from '../DrawerCartResume.module.scss'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import Button from '@material-ui/core/Button'

type deleteButtonType = {
  item: any
  idx: number
  handleDeleteItem: (idx: number, item: any) => void
}

const deleteButton: React.FC<deleteButtonType> = ({ item, idx, handleDeleteItem }) => {
  return (
    <Button disableTouchRipple className={styles.deleteButton} onClick={() => { handleDeleteItem(idx, item) }}>
      <DeleteIcon fontSize='small' />
    </Button>
  )
}

export default deleteButton