import React from 'react'
import styles from '../Checkout.module.scss'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';


type stepperComponentType = {
   steps: string[]
   activeStep: number
   handleStep: (step: number) => () => void
}

const stepperComponent: React.FC<stepperComponentType> = ({ steps, activeStep, handleStep }) => {
   if (activeStep !== 3) {
      return (
         <>
            <p className={styles.subtitle}>Finalização da compra</p>
            <Stepper color='primary' activeStep={activeStep} connector={<StepConnector />} alternativeLabel classes={{ root: styles.stepper }}>
               {
                  steps.map((label, index) => (
                     <Step key={label} color='primary'>
                        <StepButton disableTouchRipple onClick={handleStep(index)}>
                           <StepLabel classes={{ active: styles.active, completed: styles.completed }}>{label}</StepLabel>
                        </StepButton>
                     </Step>
                  ))
               }
            </Stepper>
         </>
      )
   } else {
      return null;
   }
}

export default stepperComponent
