const loadMercadoPago = callback => {
   const existingScript = document.getElementById("payMercadoPago")
   if (!existingScript) {
      const script = document.createElement("script")
      script.src = "https://secure.mlstatic.com/sdk/javascript/v1/mercadopago.js"
      script.id = "payMercadoPago"
      document.body.appendChild(script)
      script.onload = () => {
         if (callback) callback()
      }
   }
   if (existingScript && callback){ callback()}
}

export default loadMercadoPago