import React from 'react'
import styles from '../Checkout.module.scss'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ImageComponent from '../../ImageComponent/SingleImageComponent'
import { MAGENTO_URL } from '../../../../userglobals'
import { ClassNamesFn } from 'classnames/types'

type checkoutResumeType = {
   cx: ClassNamesFn
   activeStep: number
   paymentMethodSelect: string
   shipmentMethod: string
   handleStep: (step: number) => () => void
   addresses: {
      firstname: string
      lastname: string
      street: string[]
      city: string
      region: {
         region_code: number
      }
      postcode: string
      telephone: string
   }[]
   selectedAddress: number
   totalPrice: number
   calculatedPrice: []
   fretePrices: any
   transactionAmount: any
}

const checkoutResume: React.FC<checkoutResumeType> = ({ cx, activeStep, paymentMethodSelect, shipmentMethod, handleStep, addresses, selectedAddress, totalPrice, calculatedPrice, fretePrices, transactionAmount }) => {
   const items = useSelector((state: any) => state.cart);
   //console.log(fretePrices)
   const className = cx({
      displayBlock: activeStep === 3,
      resumeDiv: activeStep === 3,
      displayNone: activeStep !== 3
   });

   return (
      <div className={className}>
         <div className={styles.divSubtitle}>
            <p className={styles.subtitlePayment}>Resumo da compra</p>
         </div>
         {items.map((item: any, idx: number) => {
            return (
               <div key={idx} className={styles.divSubtitle} style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div style={{ maxWidth: '25%', marginRight: '15px' }}>
                     <ImageComponent
                        imgUrl={MAGENTO_URL + 'media/catalog/product' + item.visibleCartItem.image + "?width=93&auto=webp"}
                        altImg={item.visibleCartItem.name}
                        heightContainer={93}
                        widthContainer={93}
                        _class={styles.shadowImg}
                     />
                  </div>
                  <div style={{ maxWidth: '70%', display: 'flex', flexWrap: 'wrap' }}>
                     <div className={styles.font13}>
                        {item.visibleCartItem.name}
                     </div>
                     {
                        item.visibleCartItem.custom_options &&
                        <div className={styles.customOptions} style={{ minWidth: '85%' }}>
                           {item.visibleCartItem.custom_options!.map((customOption: { title: React.ReactNode; option_value: React.ReactNode; }, idx: number) => (
                              <div key={idx} className={styles.font13}>
                                 {customOption.title}: <b>{customOption.option_value}</b> <br />
                              </div>
                           ))}
                        </div>
                     }

                     <div className={styles.gridQtyPrice} style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginTop: '5px' }}>
                        <span className={styles.font13}>Quantidade: {item.visibleCartItem.qty}</span>
                        <span className={styles.active}>R${`${((+item.visibleCartItem.qty) * (item.visibleCartItem.price)).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</span>
                     </div>
                  </div>
               </div>
            )
         })}
         <div className={styles.divSubtitle}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
               <span className={styles.uppercaseBold}>Endereço de entrega</span>
               <Button onClick={handleStep(0)} variant='text' color='primary' size='small' className={styles.padding0} classes={{ label: styles.labelButton }}>Alterar</Button>
            </div>
            <div>
               <span className={styles.font13}>
                  {/* <b>{frete.servico}</b> - {frete.PrazoEntrega} dias úteis - <b>R${frete.Valor}</b> */}
               </span>
            </div>
            <div className={styles.font13}>
               {addresses[selectedAddress].firstname} {addresses[selectedAddress].lastname} <br />
               {addresses[selectedAddress].street[0]}, {addresses[selectedAddress].street[1]}, {addresses[selectedAddress].street[2]} <br />
               {addresses[selectedAddress].city}, {addresses[selectedAddress].region.region_code} <br />
                  CEP: {addresses[selectedAddress].postcode} <br />
               {addresses[selectedAddress].telephone.replace(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/, "($1) $2-$3")}
            </div>
         </div>
         <div className={styles.divSubtitle}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
               <span className={styles.uppercaseBold}>Pagamento</span>
               <Button onClick={handleStep(2)} variant='text' color='primary' size='small' className={styles.padding0} classes={{ label: styles.labelButton }}>Alterar</Button>
            </div>
            <div className={styles.paymentInfo}>
               <span className={styles.font13}>Forma de pagamento</span>
               <span className={styles.font13}>{paymentMethodSelect === 'creditcard' ? 'Cartão de Crédito' : 'Boleto Bancário'}</span>
            </div>
            <div className={styles.paymentInfo}>
               <span className={styles.font13}>Subtotal</span>
               <span className={styles.font13}>R$ {`${totalPrice.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</span>
            </div>
            <div className={styles.paymentInfo}>
               <span className={styles.font13}>Frete</span>
               <span className={styles.font13}>R$ {fretePrices!==null &&(fretePrices?.prices?.find((x: any) => x.servico === shipmentMethod.toUpperCase()).Valor)}</span>
            </div>
            <div className={styles.paymentInfo}>
               <span className={styles.font13}>Desconto</span>
               <span className={styles.font13}>- R$ xx,xx</span>
            </div>
         </div>
         <div className={styles.divTotal}>
            <span className={styles.active}>TOTAL</span>
            <span className={styles.active}>R$ {`${transactionAmount.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}</span>
         </div>
      </div>
   )
}

export default checkoutResume
