import React from 'react'
import styles from '../DrawerCartResume.module.scss'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import ClassNames from 'classnames/bind'

let cx = ClassNames.bind(styles);

type footerCartType = {
   subtotal: number
   toggleResumeCart: (bool: boolean) => void
}

const footerCart: React.FC<footerCartType> = ({
   subtotal,
   toggleResumeCart
}) => {

   return (
      <div className={styles.footer}>
         <div className={styles.gridTotal}>
            TOTAL
            <div className={styles.totalPrice}>
               R${`${subtotal.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`}
            </div>
         </div>
         <Button onClick={() => { toggleResumeCart(false) }} size='large' disableTouchRipple fullWidth className={styles.buttonFinish}>
            Confirmar
         </Button>
      </div>
   )
}

export default footerCart
