import React from 'react'
import styles from '../Checkout.module.scss'
import { ClassNamesFn } from 'classnames/types'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CreditCardForm from './creditCardForm'
import Radio from '@material-ui/core/Radio'

type paymentMethodsType = {
   cx: ClassNamesFn
   activeStep: number
   handlePaymentMethodChange: (e: {
      target: {
         value: React.SetStateAction<string>;
      };
   }) => void
   creditCardPaymentInfo: {
      transaction_amount: number;
      token: string;
      description: string;
      installments: string;
      payment_method_id: string;
      payer: {
         email: any;
      };
   }
   setCreditCardPaymentInfo: React.Dispatch<React.SetStateAction<{
      transaction_amount: number;
      token: string;
      description: string;
      installments: string;
      payment_method_id: string;
      payer: {
         email: any;
      };
   }>>
   installments: {
      installment_amount: number;
      installments: number;
      recommended_message: string;
   }[]
   setInstallments: React.Dispatch<React.SetStateAction<{
      installment_amount: number;
      installments: number;
      recommended_message: string;
   }[]>>
   cardVisible: boolean
   paymentMethodSelect: string
   cardNumber: string
   setCardNumber: React.Dispatch<React.SetStateAction<string>>
   formPaymentInfo: {
      cardholderName: string
      cardExpirationMonth: string
      cardExpirationYear: string
      securityCode: string
      docNumber: string
   }
   setFormPaymentInfo: React.Dispatch<React.SetStateAction<{
      cardholderName: string;
      cardExpirationMonth: string;
      cardExpirationYear: string;
      securityCode: string;
      docNumber: string;
   }>>
   openTooltipCVV: boolean
   handleTooltipCVVClose: () => void
   handleTooltipCVVOpen: () => void
   errorsCreditCardForm: any
}

const paymentMethods: React.FC<paymentMethodsType> = ({
   cx,
   activeStep,
   creditCardPaymentInfo,
   setCreditCardPaymentInfo,
   installments,
   setInstallments,
   cardVisible,
   handlePaymentMethodChange,
   paymentMethodSelect,
   cardNumber,
   setCardNumber,
   formPaymentInfo,
   setFormPaymentInfo,
   openTooltipCVV,
   handleTooltipCVVClose,
   handleTooltipCVVOpen,
   errorsCreditCardForm
}) => {
   const className = cx({
      displayBlock: activeStep === 2,
      mainDiv: activeStep === 2,
      displayNone: activeStep !== 2
   });

   const formCartao = cx({
      formCartao: paymentMethodSelect === 'creditcard',
      displayFlex: paymentMethodSelect === 'creditcard',
      displayNone: paymentMethodSelect !== 'creditcard'
   });

   const messageBoleto = cx({
      paymentMethod: true,
      displayBlock: paymentMethodSelect === 'billet',
      displayNone: paymentMethodSelect !== 'billet'
   });

   const creditCardClass = cx({
      paymentMethod: true,
      selectedPaymentMethod: paymentMethodSelect === 'creditcard'
   });

   const billetClass = cx({
      paymentMethod: true,
      selectedPaymentMethod: paymentMethodSelect === 'billet'
   });

   return (
      <div className={className}>
         <div className={styles.paymentMethod}>
            <p className={styles.subtitlePayment}>Forma de pagamento</p>
         </div>
         <RadioGroup aria-label="payment" name="paymentmethods" value={paymentMethodSelect} onChange={(e) => handlePaymentMethodChange(e)}>
            <div className={creditCardClass}>
               <FormControlLabel value="creditcard" label="Cartão de Crédito" control={<Radio size='small' color='primary' disableTouchRipple className={styles.radioButton} />} classes={{ label: styles.label }} />
            </div>
            <div className={billetClass}>
               <FormControlLabel value="billet" label="Boleto Bancário" control={<Radio size='small' color='primary' disableTouchRipple className={styles.radioButton} />} classes={{ label: styles.label }} />
            </div>
         </RadioGroup>
         <CreditCardForm
            formPaymentInfo={formPaymentInfo}
            setFormPaymentInfo={setFormPaymentInfo}
            formCartao={formCartao}
            creditCardPaymentInfo={creditCardPaymentInfo}
            setCreditCardPaymentInfo={setCreditCardPaymentInfo}
            installments={installments}
            setInstallments={setInstallments}
            cardVisible={cardVisible}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            openTooltipCVV={openTooltipCVV}
            handleTooltipCVVClose={handleTooltipCVVClose}
            handleTooltipCVVOpen={handleTooltipCVVOpen}
            errorsCreditCardForm={errorsCreditCardForm}
         />
         <div className={messageBoleto}>
            Clique no botão "Continuar" para confirmar seu pedido. <br /><br />
            O boleto será exibido após a finalização da compra e enviado para seu endereço de email. <br /><br />
            Seu boleto vence em 3 dias úteis.
         </div>
      </div>
   )
}

export default paymentMethods
