import React from 'react'
import { PageProps } from "gatsby"
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'
import Checkout from '../components/Checkout/Checkout'
import { Router } from "@reach/router"
import PrivateRoute from '../components/PrivateRoute/PrivateRoute'

const checkout: React.FC<PageProps> = ({ location }) => {
   return (
      <Layout location={location}>
         <SEO title='Checkout' />
         <Router>
            <PrivateRoute path="/checkout/" component={Checkout} />
         </Router>
      </Layout>
   )
}

export default checkout
