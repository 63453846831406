import React from 'react'
import '../Checkout.scss'
import styles from '../Checkout.module.scss'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Menuitem from '@material-ui/core/MenuItem'

import InputMask from 'react-input-mask';

import regions from '../../../data/regions.json'

type newAddressType = {
   handleCepConsult: (cep: any, type: string) => void
   toggleEditAddressDrawer: (index: number | null) => void
   handleEditAddress: (label: string, value: any, id: number) => void
   saveEditAdress: (idx: number) => void
   editAddress: any
   idx: number
   errorsAddress: any
}

const editAddress: React.FC<newAddressType> = ({ handleCepConsult, toggleEditAddressDrawer, handleEditAddress, idx, saveEditAdress, editAddress, errorsAddress }) => {
   const indexFirstname = errorsAddress.findIndex((x: any) => x.code === 'firstname');
   const indexLastname = errorsAddress.findIndex((x: any) => x.code === 'lastname');
   const indexCep = errorsAddress.findIndex((x: any) => x.code === 'postcode');
   const indexStreet = errorsAddress.findIndex((x: any) => x.code === 'street');
   const indexNumber = errorsAddress.findIndex((x: any) => x.code === 'number');
   const indexBlock = errorsAddress.findIndex((x: any) => x.code === 'block');
   const indexTelephone = errorsAddress.findIndex((x: any) => x.code === 'telephone');
   const indexCity = errorsAddress.findIndex((x: any) => x.code === 'city');


   return (
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '15px 15px 5px', borderBottom: '2px solid #f8f7f6' }}>
         <div style={{ width: '100%', margin: '0 0 5px' }}>
            {indexFirstname !== -1 && <div className={styles.erros}>{errorsAddress[indexFirstname].text}</div>}
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               error={indexFirstname !== -1}
               placeholder={'Nome'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               type="text"
               value={editAddress.firstname}
               onChange={(e) => handleEditAddress('firstname', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{ width: '100%', margin: '5px 0' }}>
            {indexLastname !== -1 && <div className={styles.erros}>{errorsAddress[indexLastname].text}</div>}
            <TextField
               error={indexLastname !== -1}
               classes={{ root: "textfieldFormAddresses" }}
               placeholder={'Sobrenome'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               type="text"
               value={editAddress.lastname}
               onChange={(e) => handleEditAddress('lastname', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{ width: '100%', margin: '5px 0' }}>
            {indexCep !== -1 && <div className={styles.erros}>{errorsAddress[indexCep].text}</div>}
            <InputMask
               mask="99.999-999"
               value={editAddress.postcode}
               onChange={(e) => {
                  if (e.target.value.length === 10) {
                     handleCepConsult(e.target.value, '');
                  }
                  handleEditAddress('postcode', e.target.value, idx)
               }}
               disabled={false}
               maskChar="">
               {
                  () =>
                     <TextField
                        classes={{ root: "textfieldFormAddresses" }}
                        id="postcode"
                        placeholder={'CEP'}
                        inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                        variant="outlined"
                        size="small"
                        type="text"
                        error={indexCep !== -1}
                        required
                        fullWidth
                     />
               }
            </InputMask>
         </div>
         <div style={{ width: '100%', margin: '5px 0' }}>
            {indexStreet !== -1 && <div className={styles.erros}>{errorsAddress[indexStreet].text}</div>}
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               id="street"
               error={indexStreet !== -1}
               placeholder={'Rua'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               value={editAddress.street[0]}
               type="text"
               onChange={(e) => handleEditAddress('street', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{ width: '78%', margin: '5px 5px 5px 0', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>

            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               placeholder={'Complemento'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               value={editAddress.street[3] !== undefined ? editAddress.street[3] : ""}
               onChange={(e) => handleEditAddress('complement', e.target.value, idx)}
               type="text"
               required
               fullWidth
            />
         </div>
         <div style={{ width: '20%', margin: '5px 0', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
            {indexNumber !== -1 && <div className={styles.erros}>{errorsAddress[indexNumber].text}</div>}
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               id="number"
               placeholder={'N°'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               error={indexNumber !== -1}
               variant="outlined"
               size="small"
               type="number"
               required
               fullWidth
               value={editAddress.street[1]}
               onChange={(e) => {
                  let value: any = e.target.value;
                  if (value < 0) {
                     value = 0;
                  }
                  handleEditAddress('number', value, idx)

               }}
            />
         </div>
         <div style={{ width: '100%', margin: '5px 0' }}>
            {indexBlock !== -1 && <div className={styles.erros}>{errorsAddress[indexBlock].text}</div>}
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               id="block"
               value={editAddress.street[2]}
               error={indexBlock !== -1}
               placeholder={'Bairro'}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               variant="outlined"
               size="small"
               type="text"
               onChange={(e) => handleEditAddress('block', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{ width: '100%', margin: '5px 0' }}>
            {indexCity !== -1 && <div className={styles.erros}>{errorsAddress[indexCity].text}</div>}
            <TextField
               classes={{ root: "textfieldFormAddresses" }}
               id="city"
               placeholder={'Cidade'}
               error={indexCity !== -1}
               inputProps={{ style: { fontSize: '14px', color: '#000' } }}
               value={editAddress.city}
               variant="outlined"
               size="small"
               type="text"
               onChange={(e) => handleEditAddress('city', e.target.value, idx)}
               required
               fullWidth
            />
         </div>
         <div style={{ width: '100%', margin: '5px 0' }}>

            <TextField
               select
               classes={{ root: "textfieldFormAddresses" }}
               size="small"

               value={editAddress.region.region_id.toString()}
               onChange={(e: any, child: any) => { handleEditAddress('region', child.props, idx) }}

               variant="outlined"
               fullWidth
            >
               {regions.map((region, idx) => {
                  return (
                     <Menuitem
                        classes={{ selected: styles.selectedItem }}
                        className={styles.menuItem}
                        id={region.code}
                        key={region.id}
                        value={region.id}>{region.name}
                     </Menuitem>
                  )
               })}
            </TextField>
         </div>
         <div style={{ width: '100%', margin: '5px 0 15px' }}>
            {indexTelephone !== -1 && <div className={styles.erros}>{errorsAddress[indexTelephone].text}</div>}
            <InputMask
               mask="(99) 9 9999-9999"
               onChange={(e) => handleEditAddress('telephone', e.target.value, idx)}
               value={editAddress.telephone}
               disabled={false}
               maskChar="">
               {
                  () =>
                     <TextField
                        classes={{ root: "textfieldFormAddresses" }}
                        id="telephone"
                        placeholder={'Telefone'}
                        error={indexTelephone !== -1}
                        inputProps={{ style: { fontSize: '14px', color: '#000' } }}
                        variant="outlined"
                        size="small"
                        type="text"
                        required
                        fullWidth
                     />
               }
            </InputMask>
         </div>

         <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button onClick={() => saveEditAdress(idx)} variant='outlined' color='primary' style={{ textTransform: 'none', width: '55%', fontWeight: 'bold' }}>Salvar alterações</Button>
         </div>
         <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button onClick={() => toggleEditAddressDrawer(idx)} variant='text' color='primary' style={{ textTransform: 'none', width: '40%' }}>Cancelar</Button>
         </div>
      </div>
   )
}

export default editAddress